/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { PPAStep } from "../types";
import { useNavigate, Outlet } from "react-router-dom";
import {
  applyMatch,
  customizeImages,
  extractColors,
  summarize,
  customizeJson,
  generateJson,
  mygenerateJson,
} from "../actions/pixel_plus_ai";
import { toast } from "react-toastify";
import { IPContext } from "./IPContext";
const html_1 = require("../pages/editor/resource/utils/html");
const store = require("../pages/editor/resource/model/store");
import { IoClose } from "react-icons/io5";
import { updateSVGInHTML } from "../utilities/UpdateSVG";
import { base64ToPolygon } from "../utilities/Base64ToPolygon";

interface Match {
  image_path: string;
  best_match_text: string;
  confidence: number;
  image_name: string;
}

interface Color {
  colorName: string;
  colorCode: string;
}

interface Font {
  fontFace: FontFace;
  fontName: string;
  fontFile: File;
}

interface PPAContextState {
  isBusy: boolean;
  setIsBusy: (value: boolean) => void;
  currentStep: PPAStep;
  goToStep: (step: PPAStep) => () => void;
  summaries: string[];
  doSummarize: (text: string, mode: "extract" | "summarize") => Promise<any>;
  deleteAllSummarises: () => void;
  updateSummary: (index: number, text: string) => void;
  deleteSummary: (index: number) => void;
  doMatch: () => Promise<any>;
  imageFiles: FileList | null;
  setImageFiles: Dispatch<SetStateAction<FileList | null>>;
  bgImages: FileList | null;
  setBgImages: Dispatch<SetStateAction<FileList | null>>;
  logoImage: FileList | null;
  setLogoImage: Dispatch<SetStateAction<FileList | null>>;
  confidence: number;
  setConfidence: (value: number) => void;
  matches: Match[];
  deleteAllMatches: () => void;
  deleteMatch: (index: number) => void;
  rule: string;
  setRule: (value: string) => void;
  colors: Color[];
  addColor: (value: Color) => void;
  removeColor: (colorName: string) => void;
  pdfFiles: FileList | null;
  setPdfFiles: Dispatch<SetStateAction<FileList | null>>;
  fonts: Font[];
  addFont: (value: Font) => void;
  removeFont: (value: string) => void;
  defaultgenerate: (selectedImages: number[]) => Promise<void>;
  myGenerate: (selectedImages: number[]) => Promise<void>;
  customizeGenerate: () => Promise<void>;
  generatedURLs: string[];
  setGeneratedURLs: (content: string[]) => void;
  generatedImages: string[];
  setGeneratedImages: (content: string[]) => void;
  generatedHTML: string[];
  setGeneratedHTML: (content: string[]) => void;
  generatedJson: string[];
  setGeneratedJson: (content: string[]) => void;
  htmlContent: string;
  setHtmlContent: (content: string) => void;
  jsonContent: string;
  setJsonContent: (content: string) => void;
  inputText: string;
}

const PPAContext = createContext<PPAContextState | null>(null);

export const PPAProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [step, setStep] = useState<PPAStep>("content");
  const [summaries, setSummaries] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<FileList | null>(null);
  const [bgImages, setBgImages] = useState<FileList | null>(null);
  const [logoImage, setLogoImage] = useState<FileList | null>(null);
  const [confidence, setConfidence] = useState(0.3);
  const [matches, setMatches] = useState<Match[]>([]);
  const [pdfFiles, setPdfFiles] = useState<FileList | null>(null);
  const [fonts, setFonts] = useState<Font[]>([]);
  const [rule, setRule] = useState<string>("");
  const [colors, setColors] = useState<
    { colorName: string; colorCode: string }[]
  >([]);
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const [generatedURLs, setGeneratedURLs] = useState<string[]>([]);
  const [generatedJson, setGeneratedJson] = useState<string[]>([]);
  const [generatedHTML, setGeneratedHTML] = useState<string[]>([]);
  const [htmlContent, setHtmlContent] = useState("");
  const [jsonContent, setJsonContent] = useState("");
  const [inputText, setInputText] = useState("");
  const [noMatch, setNoMatch] = useState(false);
  const [noElement, setNoElement] = useState(false);

  const { ip } = useContext(IPContext);

  const doSummarize = async (text: string, mode: "summarize" | "extract") => {
    if (!isBusy) {
      setIsBusy(true);
      toast.info("Summarization is started! Please wait...");
      try {
        const modifiedData = {
          text,
          ip,
          mode,
        };
        setInputText(text);
        const res = await summarize([modifiedData]);
        if (res?.data["data"].length > 1) {
          setSummaries(res?.data["data"]);
          toast.success("Completed summarization.");
        } else {
          // If the length is not greater than one, call doSummarize again
          await doSummarize(text, mode);
        }
      } catch (e: any) {
        if (e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed summarization.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const deleteAllSummarises = () => {
    setSummaries([]);
  };

  const updateSummary = (index: number, text: string) => {
    setSummaries(summaries.map((_, i) => (i === index ? text : _)));
  };

  const deleteSummary = (index: number) => {
    setSummaries(summaries.filter((_, i) => i !== index));
  };

  const doMatch = async () => {
    if (!isBusy) {
      setIsBusy(true);
      setNoMatch(false);
      toast.info("Matching is started! Please wait...");
      try {
        const formData = new FormData();
        if (imageFiles) {
          for (let i = 0; i < imageFiles.length; i++) {
            formData.append("files", imageFiles[i]);
          }
        }
        formData.append("summaries", JSON.stringify(summaries));
        formData.append("confidence", JSON.stringify(confidence));
        formData.append("ip", ip);
        const res = await applyMatch(formData);
        if (res?.data["data"].length > 0) {
          setMatches(res.data["data"]);
          toast.success("Completed matching");
        } else {
          setNoMatch(true); // Set noMatch state to true
          toast.error("No matching images. You should upload relevant images.");
        }
      } catch (e: any) {
        if (e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed matching.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const deleteAllMatches = () => {
    setMatches([]);
  };

  const deleteMatch = (index: number) => {
    setMatches(matches.filter((_, i) => i !== index));
  };

  const addColor = (color: Color) => {
    setColors((prv) => [...prv, color]);
  };

  const removeColor = (colorName: string) => {
    setColors((prv) => prv.filter((color) => color.colorName !== colorName));
  };

  const addFont = (value: Font) => {
    setFonts((prv) => {
      const isExist = !!prv.find((font) => font.fontName === value.fontName);
      if (isExist) {
        toast.warning(`${value.fontName} already exist`);
        return prv;
      }
      return [...prv, value];
    });
  };

  const removeFont = (value: string) => {
    setFonts((prv) => prv.filter((font) => font.fontName !== value));
  };

  const defaultgenerate = async (selectedImages: number[]) => {
    if (!isBusy) {
      setIsBusy(true);
      toast.info("Generating is started! Please wait...");
      try {
        const formData = new FormData();
        const randomMatch = matches[Math.floor(Math.random() * matches.length)];
        formData.append("match", JSON.stringify(randomMatch));
        formData.append("colors", JSON.stringify(colors));
        formData.append("rule", rule);
        if (logoImage) {
          formData.append("logo", logoImage[0]);
        }
        if (bgImages) {
          for (let i = 0; i < bgImages.length; i++) {
            formData.append("backgrounds", bgImages[i]);
          }
        }
        formData.append("ip", ip);
        formData.append("numbers", JSON.stringify(selectedImages));
        if (fonts && fonts.length > 0) {
          for (let i = 0; i < fonts.length; i++) {
            formData.append("fonts", fonts[i].fontFile);
          }
          formData.append(
            "fontNames",
            JSON.stringify(fonts.map((font) => font.fontName))
          );
        }
        const res_Json = await generateJson(formData);

        if (res_Json?.data) {
          const htmlContent = await html_1.jsonToHTML({
            json: JSON.parse(res_Json.data.base64),
            elementHook: "",
          });
          const updated_HtmlContent = `<html><head></head><body>${htmlContent}</body></html>`;
          // Update the SVG content in the HTML
          const processedHtmlContent = updateSVGInHTML(updated_HtmlContent);

          // Create a DOM parser to parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(processedHtmlContent, "text/html");
          // Find all elements with a clip-path property
          const elements = doc.querySelectorAll('[style*="clip-path"]');
          elements.forEach((element) => {
            const style = element.getAttribute("style");
            const clipPathMatch = style.match(
              /clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/
            );
            if (clipPathMatch) {
              const base64String = clipPathMatch[1];
              try {
                const polygon = base64ToPolygon(base64String);
                const newStyle = style.replace(
                  clipPathMatch[0],
                  `clip-path: ${polygon}`
                );
                element.setAttribute("style", newStyle);
              } catch (error) {
                console.error("Error converting base64 to polygon:", error);
              }
            }
          });
          // Serialize the updated HTML content
          const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

          const img_source = res_Json.data.img_source;

          const res = await customizeImages(
            updatedHtmlContent,
            ip,
            inputText,
            pdfFiles,
            img_source
          );

          if (res?.data) {
            setGeneratedJson((prevResult) => [
              ...prevResult,
              res_Json.data.base64,
            ]);
            setGeneratedHTML((prevResult) => [
              ...prevResult,
              updatedHtmlContent,
            ]);
            setGeneratedHTML((prevResult) => [
              ...prevResult,
              updatedHtmlContent,
            ]);
            setGeneratedImages((prvResult) => [
              ...prvResult,
              res?.data.img_string,
            ]);
            setGeneratedURLs((prvResult) => [...prvResult, res?.data.img_url]);
          }
        }
        toast.success("Completed generating");
      } catch (e: any) {
        if (e.message && e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed generating.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const myGenerate = async (selectedImages: number[]) => {
    if (!isBusy) {
      setIsBusy(true);
      toast.info("Generating is started! Please wait...");
      try {
        const formData = new FormData();
        const randomMatch = matches[Math.floor(Math.random() * matches.length)];
        formData.append("match", JSON.stringify(randomMatch));
        formData.append("colors", JSON.stringify(colors));
        formData.append("rule", rule);
        formData.append("ip", ip);
        formData.append("numbers", JSON.stringify(selectedImages));
        formData.append("logo", logoImage[0]);
        if (bgImages) {
          for (let i = 0; i < bgImages.length; i++) {
            formData.append("backgrounds", bgImages[i]);
          }
        }
        formData.append("logo", logoImage[0]);
        if (bgImages) {
          for (let i = 0; i < bgImages.length; i++) {
            formData.append("backgrounds", bgImages[i]);
          }
        }
        if (fonts && fonts.length > 0) {
          for (let i = 0; i < fonts.length; i++) {
            formData.append("fonts", fonts[i].fontFile);
          }
          formData.append(
            "fontNames",
            JSON.stringify(fonts.map((font) => font.fontName))
          );
        }
        const res_Json = await mygenerateJson(formData);

        if (res_Json?.data) {
          const htmlContent = await html_1.jsonToHTML({
            json: JSON.parse(res_Json.data.base64),
            elementHook: "",
          });
          const updated_HtmlContent = `<html><head></head><body>${htmlContent}</body></html>`;
          // Update the SVG content in the HTML
          const processedHtmlContent = updateSVGInHTML(updated_HtmlContent);

          // Create a DOM parser to parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(processedHtmlContent, "text/html");
          // Find all elements with a clip-path property
          const elements = doc.querySelectorAll('[style*="clip-path"]');
          elements.forEach((element) => {
            const style = element.getAttribute("style");
            const clipPathMatch = style.match(
              /clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/
            );
            if (clipPathMatch) {
              const base64String = clipPathMatch[1];
              try {
                const polygon = base64ToPolygon(base64String);
                const newStyle = style.replace(
                  clipPathMatch[0],
                  `clip-path: ${polygon}`
                );
                element.setAttribute("style", newStyle);
              } catch (error) {
                console.error("Error converting base64 to polygon:", error);
              }
            }
          });
          // Serialize the updated HTML content
          const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

          const img_source = res_Json.data.img_source;
          const res = await customizeImages(
            updatedHtmlContent,
            ip,
            inputText,
            pdfFiles,
            img_source
          );

          if (res?.data) {
            setGeneratedJson((prevResult) => [
              ...prevResult,
              res_Json.data.base64,
            ]);
            setGeneratedHTML((prevResult) => [
              ...prevResult,
              updatedHtmlContent,
            ]);
            setGeneratedImages((prvResult) => [
              ...prvResult,
              res?.data.img_string,
            ]);
            setGeneratedURLs((prvResult) => [...prvResult, res?.data.img_url]);
          }
        }
        toast.success("Completed generating");
      } catch (e: any) {
        if (e.message && e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed generating.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const customizeGenerate = async () => {
    if (!isBusy) {
      setIsBusy(true);
      toast.info("Generating is started! Please wait...");
      try {
        const formData = new FormData();
        const randomMatch = matches[Math.floor(Math.random() * matches.length)];
        formData.append("match", JSON.stringify(randomMatch));
        formData.append("colors", JSON.stringify(colors));
        formData.append("rule", rule);
        formData.append("ip", ip);
        formData.append("logo", logoImage[0]);
        if (bgImages) {
          for (let i = 0; i < bgImages.length; i++) {
            formData.append("backgrounds", bgImages[i]);
          }
        }
        formData.append("jsondata", JSON.stringify(jsonContent));
        if (fonts && fonts.length > 0) {
          for (let i = 0; i < fonts.length; i++) {
            formData.append("fonts", fonts[i].fontFile);
          }
          formData.append(
            "fontNames",
            JSON.stringify(fonts.map((font) => font.fontName))
          );
        }

        const res_Json = await customizeJson(formData);
        if (res_Json?.data) {
          const htmlContent = await html_1.jsonToHTML({
            json: JSON.parse(res_Json.data.base64),
            elementHook: "",
          });

          const updated_HtmlContent = `<html><head></head><body>${htmlContent}</body></html>`;
          // Update the SVG content in the HTML
          const processedHtmlContent = updateSVGInHTML(updated_HtmlContent);

          // Create a DOM parser to parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(processedHtmlContent, "text/html");
          // Find all elements with a clip-path property
          const elements = doc.querySelectorAll('[style*="clip-path"]');
          elements.forEach((element) => {
            const style = element.getAttribute("style");
            const clipPathMatch = style.match(
              /clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/
            );
            if (clipPathMatch) {
              const base64String = clipPathMatch[1];
              try {
                const polygon = base64ToPolygon(base64String);
                const newStyle = style.replace(
                  clipPathMatch[0],
                  `clip-path: ${polygon}`
                );
                element.setAttribute("style", newStyle);
              } catch (error) {
                console.error("Error converting base64 to polygon:", error);
              }
            }
          });
          // Serialize the updated HTML content
          const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

          const img_source = res_Json.data.img_source;
          const res = await customizeImages(
            updatedHtmlContent,
            ip,
            inputText,
            pdfFiles,
            img_source
          );
          if (res?.data) {
            setGeneratedJson((prevResult) => [
              ...prevResult,
              res_Json.data.base64,
            ]);
            setGeneratedHTML((prevResult) => [
              ...prevResult,
              updatedHtmlContent,
            ]);
            setGeneratedImages((prvResult) => [
              ...prvResult,
              res?.data.img_string,
            ]);
            setGeneratedURLs((prvResult) => [...prvResult, res?.data.img_url]);
          }
        }
        toast.success("Completed generating");
      } catch (e: any) {
        if (e.message && e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed generating.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const extractColor = async () => {
    if (!isBusy) {
      try {
        setIsBusy(true);
        toast.info("Extracting color from pdf! Please wait...");
        const formData = new FormData();

        if (pdfFiles) {
          for (let i = 0; i < pdfFiles.length; i++) {
            formData.append("files", pdfFiles[i]);
          }
        }
        formData.append("ip", ip);
        const res = await extractColors(formData);
        if (res?.data["data"].length > 0) {
          setColors(res?.data["data"]);
          toast.success("Completed color extraction");
        } else {
          setNoElement(true);
          toast.error(
            "No brand features. You should add them manually or upload new file."
          );
        }
      } catch (e: any) {
        if (e.message.includes("401")) {
          toast.error("The token has expired. Please login again.");
        } else {
          toast.error("Failed color extraction.");
        }
        console.error(e);
      } finally {
        setIsBusy(false);
      }
    }
  };

  const goToStep = (target: PPAStep) => () => {
    navigate(`/pixelplusai/${target}`);
  };

  useEffect(() => {
    const paths = window.location.pathname.split("/");
    if (paths.length >= 3 && paths[1] === "pixelplusai" && paths[2]) {
      setStep(paths[2] as PPAStep);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (pdfFiles && pdfFiles.length > 0) {
      extractColor();
    }
  }, [pdfFiles]);

  return (
    <PPAContext.Provider
      value={{
        isBusy,
        rule,
        fonts,
        colors,
        pdfFiles,
        matches,
        confidence,
        imageFiles,
        summaries,
        currentStep: step,
        setIsBusy,
        goToStep,
        deleteAllSummarises,
        deleteSummary,
        updateSummary,
        doSummarize,
        setImageFiles,
        setConfidence,
        doMatch,
        deleteAllMatches,
        deleteMatch,
        setPdfFiles,
        setRule,
        addColor,
        removeColor,
        addFont,
        removeFont,
        defaultgenerate,
        myGenerate,
        customizeGenerate,
        generatedImages,
        setGeneratedImages,
        generatedURLs,
        setGeneratedURLs,
        generatedHTML,
        setGeneratedHTML,
        htmlContent,
        setHtmlContent,
        jsonContent,
        setJsonContent,
        generatedJson,
        setGeneratedJson,
        inputText,
        logoImage,
        setLogoImage,
        bgImages,
        setBgImages,
      }}
    >
      {children}
      <Outlet />
      {noMatch && (
        <>
          <div
            className="fixed right-4 top-4 w-[32px] h-[32px] cursor-pointer z-20"
            onClick={() => setNoMatch(false)}
          >
            <IoClose size={32} />
          </div>
          <div className="w-[100vw] h-[100vh] fixed left-0 top-0 bg-[#808080] bg-opacity-90 flex flex-col justify-center items-center gap-9">
            <div className="text-[32px] text-white mt-5 px-12">
              None of the images could be tagged so please adjust the Confidence
              Level in the Step 2 or upload images which are relevant to the
              description text in step 1.
            </div>
          </div>
        </>
      )}
      {noElement && (
        <>
          <div
            className="fixed right-4 top-4 w-[32px] h-[32px] cursor-pointer z-20"
            onClick={() => setNoElement(false)}
          >
            <IoClose size={32} />
          </div>
          <div className="w-[100vw] h-[100vh] fixed left-0 top-0 bg-[#808080] bg-opacity-90 flex flex-col justify-center items-center gap-9">
            <div className="text-[32px] text-white mt-5 px-12">
              No brand features are extracted from the source file. Upload a new
              file or add the brands manually.
            </div>
          </div>
        </>
      )}
    </PPAContext.Provider>
  );
};

export const usePPAContext = () => {
  const context = useContext(PPAContext);
  if (!context) {
    throw new Error("usePPAContext should be used inside of PPAProvider");
  }
  return context;
};
