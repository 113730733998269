import React, { useEffect, useState } from "react";
import AnimationLayout from "../../../layouts/AnimationLayout";
import { usePPAContext } from "../../../hooks/usePPAContext";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import { FaImages } from "react-icons/fa6";
import ImageViewer from "./ImageViewer";
import { useAuth } from "../../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import ReactDOM from "react-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdCheckBox } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { getMyTemps } from "../../../actions/pixel_plus_ai";
import Loading from "../../../components/Loading";

const PPACreation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    matches,
    goToStep,
    isBusy,
    defaultgenerate,
    myGenerate,
    customizeGenerate,
    generatedImages,
  } = usePPAContext();
  const { UserInfo, subScriptionLevel, isAdmin } = useAuth();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const [selectedGenerateBtn, setSelectedGenerateBtn] =
    useState<boolean>(false);
  const [selectedMyGenerateBtn, setSelectedMyGenerateBtn] =
    useState<boolean>(false);
  const [subScriptionId, setSubScriptionId] = useState();
  const [showImages, setShowImages] = useState<boolean>(false);
  const [showMyImages, setShowMyImages] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [loadingMyImage, setLoadingMyImage] = useState<boolean> (false);

  const onGenerate = async (numbers: number[]) => {
    setSelectedGenerateBtn(false);
    setShowImages(false);
    setSelectedImages([]);
    setAllSelected(false);
    const userInfo = await UserInfo();
    const credit = userInfo.data.credit;
    const id = userInfo.data.subscription;
    setSubScriptionId(id);
    for (let i = 0; i < Math.min(6, credit) && credit > 0; i++) {
      await defaultgenerate(numbers);
    }
    if (!isAdmin || credit == 0) {
      toast.error("No available credits!");
    }
  };

  const onMyGenerate = async (numbers: number[]) => {
    setSelectedImages([]);
    setAllSelected(false);
    setSelectedGenerateBtn(false);
    setSelectedMyGenerateBtn(false);
    setShowImages(false);
    setShowMyImages(false);
    const userInfo = await UserInfo();
    const credit = userInfo.data.credit;
    const id = userInfo.data.subscription;
    setSubScriptionId(id);
    for (let i = 0; i < Math.min(6, credit) && credit > 0; i++) {
      await myGenerate(numbers);
    }
    if (!isAdmin || credit == 0) {
      toast.error("No available credits!");
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedImages([]);
    } else {
      setSelectedImages(templateImages.map((_, index) => index));
    }
    setAllSelected(!allSelected);
  };

  const handleSelectMyAll = () => {
    if (allSelected) {
      setSelectedImages([]);
    } else {
      // setSelectedImages(templateMyImages.map((_, index) => index));
      setSelectedImages(templateMyImages.map(({id}, index) => {
        return id;
      }));
    }
    setAllSelected(!allSelected);
  };

  const onClose = () => {
    setSelectedImageIndex(-1);
  };

  const onNext = () => {
    setSelectedImageIndex((prv) =>
      prv < generatedImages.length - 1 ? prv + 1 : generatedImages.length - 1
    );
  };

  const onPrevious = () => {
    setSelectedImageIndex((prv) => (prv > 0 ? prv - 1 : 0));
  };

  useEffect(() => {
    if (!matches || matches.length <= 0) {
      toast.warning("You must complete previous step");
      goToStep("images")();
    }
  }, [matches]);

  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const executeAsyncCustomizeGeneration = async () => {
    const userInfo = await UserInfo();
    const credit = userInfo.data.credit;
    for (let i = 0; i < Math.min(6, credit) && credit > 0; i++) {
      await customizeGenerate();
    }
    if (!isAdmin || credit == 0) {
      toast.error("No available credits!");
    }
  };

  useEffect(() => {
    const action = searchParams.get("action");
    if (action === "customize_generate") {
      executeAsyncCustomizeGeneration();
    }
  }, [searchParams]);

  const templateImages = Array.from(
    { length: 2 },
    (_, i) =>
      `${process.env.PUBLIC_URL}/templates/temp${String(i + 1).padStart(
        2,
        "0"
      )}.png`
  );

  const [templateMyImages, setTemplateMyImages] = useState([]);

  useEffect(() => {
    // fetch my template images
    getMyTemps().then((response: any) => {
      Promise.all(
        response.data.map(
          ({ id, image_url }: { id: number; image_url: string }) =>
            fetch(image_url)
              .then((res) => res.text())
              .then((base64) => ({
                id,
                image: `data:image/png;base64,${base64}`,
              }))
        )
      ).then((images) => {
        setTemplateMyImages(images);
      });
    });
  }, []);

  const handleUseMyTemplates = () => {
    setShowMyImages(true);
    setSelectedMyGenerateBtn(true);
    setLoadingMyImage(true);
  
    // Fetch my template images
    getMyTemps().then((response: any) => {
      Promise.all(
        response.data.map(
          ({ id, image_url }: { id: number; image_url: string }) =>
            fetch(image_url)
              .then((res) => res.text())
              .then((base64) => ({
                id,
                image: `data:image/png;base64,${base64}`,
              }))
        )
      ).then((images) => {
        setLoadingMyImage(false);
        setTemplateMyImages(images);
      });
    });
  };

  return (
    <>
      {selectedGenerateBtn && (
        <div className="w-[100vw] h-[100vh] fixed left-0 top-0 bg-[#808080] bg-opacity-90 flex flex-col justify-center items-center gap-9 z-10">
          <div
            className="fixed right-4 top-4 w-[32px] h-[32px] cursor-pointer z-20"
            onClick={() => {
              setSelectedGenerateBtn(false);
              setSelectedMyGenerateBtn(false);
              setShowImages(false);
              setShowMyImages(false);
              setSelectedImages([]);
              setAllSelected(false);
            }}
          >
            <IoClose size={32} />
          </div>
          {showImages ? (
            <div className="w-[100vw] h-[100vh] fixed left-0 top-0 bg-[#808080] bg-opacity-90 flex flex-col justify-center items-center gap-9">
              <div className="text-[32px] text-white mt-5">
                Choose the default templates you want to generate..
              </div>
              <div className="overflow-y-auto grid grid-cols-3 gap-5 mb-5 max-h-[70vh] w-[80vw] p-5 rounded-lg">
                {templateImages.map((imagePath, index) => (
                  <div
                    key={index}
                    className="relative cursor-pointer"
                    onClick={() => {
                      setSelectedImages((prevSelectedImages) => {
                        if (prevSelectedImages.includes(index)) {
                          return prevSelectedImages.filter((i) => i !== index);
                        } else {
                          return [...prevSelectedImages, index];
                        }
                      });
                    }}
                  >
                    <img
                      src={imagePath}
                      alt={`Template ${index + 1}`}
                      className="rounded h-[1/2] object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none"
                    />
                    {selectedImages.includes(index) && (
                      <div className="absolute -top-[5px] -right-[5px] text-light-600 rounded p-1 text-[1.2rem] bg-[#e41e56]">
                        <MdCheckBox />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex flex-row gap-4">
                <button
                  className="mt-5 rounded px-4 py-2 cursor-pointer bg-dark-400 border-2 border-dark-800 text-light-500"
                  onClick={handleSelectAll}
                >
                  {allSelected ? "Deselect All" : "Select All"}
                </button>
                <Button
                  onClick={() => onGenerate(selectedImages)}
                  className="text-light-600 mt-5"
                >
                  Generate
                </Button>
              </div>
            </div>
          ) : showMyImages ? (
            <div className="w-[100vw] h-[100vh] fixed left-0 top-0 bg-[#808080] bg-opacity-90 flex flex-col justify-center items-center gap-9">
              {loadingMyImage ? <Loading loading={true} /> : (
              templateMyImages.length === 0 ? (
                <div className="text-[32px] text-white mt-5">
                  You do not have any templates saved, please create new
                  templates and publish to reuse.
                </div>
              ) : (
                <>
                  <div className="text-[32px] text-white mt-5">
                    Choose your templates you want to generate..
                  </div>
                  <div className="overflow-y-auto grid grid-cols-3 gap-5 mb-5 max-h-[70vh] w-[80vw] p-5 rounded-lg">
                    {templateMyImages.map(({ id, image }) => (
                      <div
                        key={id}
                        className="relative cursor-pointer"
                        onClick={() => {
                          setSelectedImages((prevSelectedImages) => {
                            if (prevSelectedImages.includes(id)) {
                              return prevSelectedImages.filter((i) => i !== id);
                            } else {
                              return [...prevSelectedImages, id];
                            }
                          });
                        }}
                      >
                        <img
                          src={image}
                          alt={`NewTemplate ${id}`}
                          className="rounded h-[1/2] object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none"
                        />
                        {selectedImages.includes(id) && (
                          <div className="absolute -top-[5px] -right-[5px] text-light-600 rounded p-1 text-[1.2rem] bg-[#e41e56]">
                            <MdCheckBox />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row gap-4">
                    <button
                      className="mt-5 rounded px-4 py-2 cursor-pointer bg-dark-400 border-2 border-dark-800 text-light-500"
                      onClick={handleSelectMyAll}
                    >
                      {allSelected ? "Deselect All" : "Select All"}
                    </button>
                    <Button
                      onClick={() => onMyGenerate(selectedImages)}
                      className="text-light-600 mt-5"
                    >
                      Generate
                    </Button>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center gap-9">
              <Button
                onClick={() => {
                  navigate("/pixelplusai/design-editor");
                }}
                className="text-light-600"
              >
                Customize New Templates
              </Button>
              <Button
                onClick={() => {
                  setShowImages(true);
                  setSelectedGenerateBtn(true);
                }}
                className="text-light-600"
              >
                Use Default Templates
              </Button>
              <Button
                onClick={handleUseMyTemplates}
                className="text-light-600"
              >
                Use My Templates
              </Button>
            </div>
          )}
        </div>
      )}
      <AnimationLayout className="gap-3">
        <div className="relative rounded w-full border dark:border-dark-700">
          <div className="bg-light-600 dark:bg-dark-200 flex-1 h-full rounded p-10 flex flex-col overflow-clip justify-between gap-3">
            <div className="mb-[20px] pl-[20px]">
              <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
                AI-Generated Creation
              </h3>
            </div>
            {generatedImages.length > 0 ? (
              <div className="overflow-y-auto overflow-x-hidden grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-1 py-2">
                {generatedImages.map((base64String, index) => {
                  return (
                    <div
                      key={index}
                      className="relative cursor-pointer h-[fit-content] rounded border border-2 border-lightgrey dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none"
                      onClick={() => setSelectedImageIndex(index)}
                    >
                      <img
                        src={`data:image/png;base64,${base64String}`}
                        alt="Result image"
                        className="rounded object-cover"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-[5rem] w-full h-full flex items-center justify-center text-black dark:text-light-200">
                <FaImages />
              </div>
            )}
            <div className="flex flex-row-reverse gap-5 mt-[20px]">
              <Button
                onClick={() => setSelectedGenerateBtn(!selectedGenerateBtn)}
                disabled={isBusy}
              >
                <p className="flex items-center gap-2 text-light-600">
                  {isBusy ? (
                    <HashLoader
                      color="#e9e9e9"
                      size={20}
                      speedMultiplier={1.5}
                    />
                  ) : (
                    "Generate"
                  )}
                </p>
              </Button>
            </div>
            <ImageViewer
              index={selectedImageIndex}
              onNext={onNext}
              onClose={onClose}
              onPrevious={onPrevious}
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};

export default PPACreation;
