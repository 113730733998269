import React, { ReactElement } from "react";
import Button from "./Button";

interface DropboxProps {
  className?: string;
  icon?: ReactElement;
  title: string;
  description?: string;
  onChange?: (files: FileList | null) => void;
  multiple?: boolean;
  accept?: string;
  name?: string;
}

const Dropbox: React.FC<DropboxProps> = ({
  className = "",
  icon,
  title,
  description,
  onChange,
  multiple = true,
  accept = "*.*",
  name = "dropzone-file",
}) => {
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (onChange) onChange(files);
  };
  const classNames = `dark:bg-dark-500 bg-[#F4F4F4] text-center rounded border-2 border-dashed border-light-500 dark:border-dark-700 flex flex-col items-center justify-center p-3 ${className}`;
  return (
    <label htmlFor={name} className={classNames}>
      <span className="text-[2rem] text-black dark:text-light-200">{icon}</span>
      <p className="text-black dark:text-light-600 text-[1.1rem] mt-2">
        {title}
      </p>
      <p className="text-dark-600 dark:text-light-200">Or</p>
      <Button
        variant="outline"
        className="mt-1"
        onClick={() => document.getElementById(name)?.click()}
      >
        Select here
      </Button>
      <p className="mt-3 text-[0.8rem] text-black dark:text-light-200">
        {description}
      </p>
      <input
        id={name}
        type="file"
        accept={accept}
        className="hidden"
        multiple={multiple}
        onChange={handleFileUpload}
      />
    </label>
  );
};

export default Dropbox;
