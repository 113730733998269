import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Editor from "./pages/editor/index";
import { IPContext } from "./hooks/IPContext";
import { PPAProvider } from "./hooks/usePPAContext";
import ProtectedRoute from "./ProtectedRoute";
import PixelPlusAILayout from "./layouts/PixelPlusAILayout";
import {
  PPACallToActionButton,
  PPAContent,
  PPACreation,
  PPAElements,
  PPAImages,
  PPABgLogo
} from "./pages/pixel_plus_ai";
import Navbar from "./components/Navbar";
import { SubScriptionProvider } from "./hooks/useSubScription";
import SubscriptionLayout from "./layouts/SubscriptionLayout";
import SubscriptionPage from "./pages/subscription/SubscriptionPage";
import CheckoutForm from "./pages/subscription/CheckoutForm";
import SuccessPage from "./pages/subscription/SuccessPage";
import Profile from "./pages/profile/Profile";
import Login from "./pages/auth/login/Login";
import Register from "./pages/auth/register/Register";
import ResetPassword from "./pages/auth/reset-pwd/ResetPassword";
import ForgotPassword from "./pages/auth/reset-pwd/ForgotPassword";
import UserManagement from "./userManagement/UserManagementPage";
import axios from "axios";
import { apiBaseUrl, authenticatedUrl } from "./config";
import useAuth from "./hooks/useAuth";
import Viewlogs from "./pages/viewlogs/Viewlogs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";

axios.defaults.baseURL = apiBaseUrl;

const App = () => {
  const { isAdmin } = useAuth();
  const { darkMode } = useContext(IPContext);

  return (
      <div>
        <Routes>
          <Route path="/" element={<PPAProvider />}>
            <Route index element={<Navigate to="/pixelplusai" />} />
            <Route
              path="/pixelplusai"
              element={
                <ProtectedRoute>
                  <PixelPlusAILayout />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="content" />} />
              <Route path="content" element={<PPAContent />} />
              <Route path="images" element={<PPAImages />} />
              <Route path="bglogo" element={<PPABgLogo />} />
              <Route path="elements" element={<PPAElements />} />
              <Route path="button" element={<PPACallToActionButton />} />
              <Route path="creation" element={<PPACreation />} />
            </Route>

            <Route
              path="/pixelplusai/design-editor"
              element={
                <ProtectedRoute>
                  <div className="h-[100vh] min-h-[900px] flex flex-col">
                    <Navbar />
                    <Outlet />
                  </div>
                </ProtectedRoute>
              }
            >
              <Route index element={<Editor />} />
            </Route>

            <Route
              path="/new-design"
              element={
                <ProtectedRoute>
                  <div className="h-[100vh] min-h-[900px] flex flex-col">
                    <Navbar />
                    <Outlet />
                  </div>
                </ProtectedRoute>
              }
            >
              <Route index element={<Editor />} />
            </Route>
            
            <Route
              path="/pixelplusai/subscription"
              element={
                <SubScriptionProvider>
                  <ProtectedRoute>
                    <SubscriptionLayout />
                  </ProtectedRoute>
                </SubScriptionProvider>
              }
            >
              <Route index element={<SubscriptionPage />} />
              <Route path="checkout" element={<CheckoutForm />} />
              <Route path="success" element={<SuccessPage />} />
            </Route>
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Register />} />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <UserManagement data={[]} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to={authenticatedUrl} />} />

          <Route
            path="/logs"
            element={
              isAdmin ? ( // Check if the user is an admin
                <div className="h-[100vh] min-h-[900px] flex flex-col">
                  <Navbar />
                  <Outlet />
                </div>
              ) : (
                <Navigate to="/" /> // Redirect non-admin users
              )
            }
          >
            <Route index element={<Viewlogs />} />
          </Route>
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme={darkMode ? "dark": "light"}
        />
      </div>
  );
};

export default App;
