import React from 'react';
import { usePPAContext } from "../../../hooks/usePPAContext";
import { MdDelete } from "react-icons/md";
import AnimatedButton from "../../../components/AnimatedButton";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";

const PPAImageMatching = () => {
  const {
    imageFiles,
    matches,
    goToStep,
    isBusy,
    deleteAllMatches,
    deleteMatch,
  } = usePPAContext();

  const onDiscard = () => {
    deleteAllMatches();
  };

  const handleDelete = (index: number) => {
    deleteMatch(index);
  };

  return (
    <>
      <Loading
        loading={isBusy}
        className={"rounded border h-full border-dark-700"}
      />
      <div className="flex flex-col">
        <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold mb-5">
          AI-Generated Content
        </h3>
        <div className="overflow-y-auto overflow-x-hidden grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-1 py-2">
          {matches.map((match, index) => {
            const file = imageFiles
              ? Array.from(imageFiles).find(
                  (imageFile) => imageFile.name === match.image_name
                )
              : null;
            return file ? (
              <div
                key={index}
                className="relative p-2 rounded border [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none dark:border-dark-600 flex flex-col"
              >
                <div className="flex-1">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="rounded h-full object-cover"
                  />
                </div>
                <AnimatedButton
                  className="absolute top-[10px] right-[10px] text-[#ffffff] rounded p-1 text-[1.2rem] bg-dark-400"
                  onClick={() => handleDelete(index)}
                >
                  <MdDelete />
                </AnimatedButton>
                <p className="text-sm backdrop-blur rounded-b p-3 dark:text-light-300 text-black">
                  {match.best_match_text}
                </p>
              </div>
            ) : null;
          })}
        </div>
        <div className="flex justify-center gap-5 mt-[20px]">
          <Button variant="outline" onClick={onDiscard} disabled={isBusy}>
            Discard
          </Button>
          <Button onClick={goToStep("bglogo")} disabled={isBusy}>
            <p className="flex items-center gap-2 text-light-600">Next Step</p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PPAImageMatching;
