import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Position,
  Menu,
  Popover,
  MenuItem,
} from "@blueprintjs/core";
import { downloadFile } from "../resource/utils/download";
import { updateSVGInHTML } from "../../../utilities/UpdateSVG";
import { base64ToPolygon } from "../../../utilities/Base64ToPolygon";

export const ExportButton = observer(({ store }) => {
  const [saving, setSaving] = React.useState(false);
  const [quality, setQuality] = React.useState(1);
  
  const menu = (
    <Menu>
      <MenuItem
        icon="series-derived"
        text="Download Image"
        onClick={async () => {
          setSaving(true);
          try {
            store.pages.forEach((page, index) => {
              // do not add index if we have just one page
              const indexString =
                store.pages.length > 1 ? "-" + (index + 1) : "";
              store.saveAsImage({
                pageId: page.id,
                pixelRatio: quality,
                mimeType: "image/png",
                fileName: "bcai_result_image.png",
              });
            });
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading Image. Please try again."
            );
          }
          setSaving(false);
        }}
      />
      <MenuItem
        icon="floppy-disk"
        text="Download JSON"
        onClick={async () => {
          setSaving(true);
          try {
            const json = store.toJSON();
            const url =
              "data:text/json;base64," +
              window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));

            downloadFile(url, "bcai_result_json.json");
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading JSON. Please try again."
            );
          }
          setSaving(false);
        }}
      />
      <MenuItem
        icon="code"
        text="Download HTML"
        onClick={async () => {
          setSaving(true);
          try {
            // await store.saveAsHTML("bcai_result_html.html");
            const htmlcontent = await store.toHTML();
            const processedHtmlContent = updateSVGInHTML(htmlcontent);
            
            // Create a DOM parser to parse the HTML content
            const parser = new DOMParser();
            const doc = parser.parseFromString(processedHtmlContent, 'text/html');
            // Find all elements with a clip-path property
            const elements = doc.querySelectorAll('[style*="clip-path"]');
            elements.forEach(element => {
              const style = element.getAttribute('style');
              const clipPathMatch = style.match(/clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/);
              if (clipPathMatch) {
                const base64String = clipPathMatch[1];
                try {
                  const polygon = base64ToPolygon(base64String);         
                  const newStyle = style.replace(clipPathMatch[0], `clip-path: ${polygon}`);    
                  element.setAttribute('style', newStyle);
                } catch (error) {
                  console.error('Error converting base64 to polygon:', error);
                }
              }
            });
            // Serialize the updated HTML content
            const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

            const blob = new Blob([updatedHtmlContent], { type: "text/html" });
            const url = URL.createObjectURL(blob);
            downloadFile(url, "bcai_result_html.html");
          
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading HTML. Please try again."
            );
          }
          setSaving(false);
        }}
      />
    </Menu>
  );

  return (
    <div>
      <Popover content={menu} position={Position.BOTTOM}>
        <Button icon="import" text="Export" intent="primary" className="text-[16px]"/>
      </Popover>
    </div>
  );
});
