import axios, { AxiosResponse } from "axios";

interface SummarizeData {
  text: string;
  mode: "extract" | "summarize";
  ip: string;
}

export const generateJson = async (
  data: FormData
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/jsongenerate", data);
    return response;
  } catch (error) {
    throw new Error("Error in generating Json Data:" + error);
  }
};

export const mygenerateJson = async (
  data: FormData
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/myjsongenerate", data);
    return response;
  } catch (error) {
    throw new Error("Error in generating Json Data:" + error);
  }
};

export const customizeJson = async (
  data: FormData
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/customizejsongenerate", data);
    return response;
  } catch (error) {
    throw new Error("Error in customize Json Data:" + error);
  }
};

export const customizeImages = async (
  data: string,
  ip: string,
  inputText: string,
  pdfFiles: FileList,
  img_source: string
): Promise<AxiosResponse | null> => {
  try {
    const formdata = new FormData();
    formdata.append("htmlData", data);
    formdata.append("ip", ip);
    formdata.append("inputText", inputText);
    for (let i = 0; i < pdfFiles.length; i++)
      formdata.append("pdfFiles", pdfFiles[i]);
    formdata.append("img_source", img_source);

    const response = await axios.post(
      "/project/customizeimagegenerate",
      formdata
    );
    return response;
  } catch (error) {
    throw new Error("Error in customize generating Images:" + error);
  }
};

export const EditedImages = async (
  data: string,
  ip: string,
  img_url: string
): Promise<AxiosResponse | null> => {
  try {
    const payload = {
      htmlData: data,
      ip: ip, // Include the IP address in the payload
      img_url: img_url,
    };
    const response = await axios.post("/project/edit_image", payload, {
      headers: {
        "Content-Type": "application/json", // Set the content type to application/json
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in customize generating Images:" + error);
  }
};

export const summarize = async (
  data: SummarizeData[]
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/summarize", data);
    return response;
  } catch (error) {
    throw new Error("Error in summarize:" + error);
  }
};

export const applyMatch = async (
  data: FormData
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/match", data);
    return response;
  } catch (error) {
    throw new Error("Error in match:" + error);
  }
};

export const extractColors = async (
  data: FormData
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/project/extract", data);
    return response;
  } catch (error) {
    throw new Error("Error in extract colors from pdf:" + error);
  }
};

export const publish = async (
  jsondata: string,
  imagedata: string
): Promise<AxiosResponse | null> => {
  try {
    console.log({ jsondata, imagedata });

    const formdata = new FormData();
    formdata.append("jsondata", jsondata);
    formdata.append("imagedata", imagedata);

    const response = await axios.post("/project/publish", formdata);
    return response;
  } catch (error) {
    throw new Error("Error in publishing new templates:" + error);
  }
};

export const toImage = async (
  htmldata: string
): Promise<AxiosResponse | null> => {
  try {
    const formdata = new FormData();
    formdata.append("htmldata", htmldata);

    const response = await axios.post("/project/toImage", formdata);
    return response;
  } catch (error) {
    throw new Error("Error in converting to image:" + error);
  }
};

export const getMyTemps = async (): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.get("/project/getMyTemps");
    return response;
  } catch (error) {
    throw new Error("Error in getting templates:" + error);
  }
};
