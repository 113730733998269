export function base64ToPolygon(base64String: string): string {
    // Decode the base64 string
    const svgContent = atob(base64String);
  
    // Parse the SVG content to extract the path data
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');
    const pathElement = svgDoc.querySelector('path');
    const pathData = pathElement?.getAttribute('d');
  
    if (!pathData || !svgElement) {
      throw new Error('Invalid SVG path data');
    }
  
    // Get the SVG's width and height
    const svgWidth = parseFloat(svgElement.getAttribute('width') || '1');
    const svgHeight = parseFloat(svgElement.getAttribute('height') || '1');
  
    // Convert the path data to polygon points
    const commands = pathData.match(/[a-zA-Z][^a-zA-Z]*/g);
    let currentX = 0;
    let currentY = 0;
    const points = commands?.map(command => {
      const type = command[0];
      const coords = command.slice(1).trim().split(/\s+|,/).map(Number);
      switch (type) {
        case 'M':
        case 'L':
          currentX = coords[0];
          currentY = coords[1];
          break;
        case 'H':
          currentX = coords[0];
          break;
        case 'V':
          currentY = coords[0];
          break;
        case 'C':
          currentX = coords[4];
          currentY = coords[5];
          break;
        case 'S':
        case 'Q':
          currentX = coords[2];
          currentY = coords[3];
          break;
        case 'T':
          currentX = coords[0];
          currentY = coords[1];
          break;
        case 'Z':
          return null;
        default:
          return null;
      }
      // Normalize the coordinates
      const normalizedX = (currentX / svgWidth) * 100;
      const normalizedY = (currentY / svgHeight) * 100;
      return `${normalizedX}% ${normalizedY}%`;
    }).filter(Boolean);
  
    return `polygon(${points?.join(', ')})`;
  }