import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

// Create a context with a default value
export const IPContext = createContext({ ip: '', darkMode: true, setDarkMode: (value: boolean) => {} });

export const IPProvider = ({ children }: { children: ReactNode }) => {
  const [ip, setIp] = useState('');
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const getIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        console.log(response.data.ip)
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    getIP();
  }, []);

  return (
    <IPContext.Provider value={{ ip, darkMode, setDarkMode }}>
      {children}
    </IPContext.Provider>
  );
};