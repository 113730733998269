import Dropbox from "../../../components/Dropbox";
import Separator from "../../../components/Separator";
import TextInput from "../../../components/TextInput";
import AnimationLayout from "../../../layouts/AnimationLayout";
import { BsImages } from "react-icons/bs";
import PPAImagesPanel from "./PPAImagesShowPanel";
import { usePPAContext } from "../../../hooks/usePPAContext";
import Button from "../../../components/Button";
import Slider from "rc-slider";
import { ClockLoader } from "react-spinners";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";

const PPABgLogo = () => {
  const {
    summaries,
    confidence,
    setConfidence,
    logoImage,
    setLogoImage,
    bgImages,
    setBgImages,
    isBusy,
    doMatch,
    goToStep,
    matches,
  } = usePPAContext();

  const onMatch = () => {
    doMatch();
  };

  useEffect(() => {
    if (!summaries || summaries.length <= 0) {
      toast.warning("You must complete previous step");
      goToStep("content")();
    }
  }, [summaries]);

  return (
    <>
      <AnimationLayout className="gap-3">
        <div className="flex flex-col w-full gap-3">
          <div className="flex-1 flex w-full gap-3">
            {logoImage ? (
              <div className="flex-1 rounded border dark:border-dark-700 bg-light-600 dark:bg-dark-200 p-10">
                <Loading
                  loading={isBusy}
                  className={"absolute rounded w-full h-full"}
                />
                <div className="w-full h-full rounded flex flex-col overflow-clip justify-between gap-3">
                  <PPAImagesPanel
                    title="Uploaded Logo"
                    imageFiles={logoImage}
                    setImageFiles={setLogoImage}
                    cols={1}
                  />
                  <div className="w-full flex flex-row-reverse gap-3">
                    <Button
                      onClick={() => {
                        setLogoImage(null);
                      }}
                      variant="outline"
                      disabled={isBusy}
                    >
                      Discard
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hidden md:flex bg-light-600 dark:bg-dark-200 min-w-[300px] flex-1 h-full rounded p-10 flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
                <div className="mb-[20px] pl-[20px]">
                  <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
                    Upload Logo
                  </h3>
                  <p className="text-black dark:text-light-200">
                    Upload Logo for ad creation
                  </p>
                </div>
                <div className="flex-1 overflow-clip mb-2">
                  <Dropbox
                    name="logo-dropbox"
                    className="w-full h-full"
                    title="Drag and drop images here"
                    icon={<BsImages />}
                    description="Supports: SVG, PNG, JPG or GIF (MAX. 800x400px)"
                    onChange={setLogoImage}
                    accept=".png,.jpg,.bmp,.svg,.gif,.jpeg"
                    multiple={false}
                  />
                </div>
              </div>
            )}
            {bgImages ? (
              <div className="flex-1 rounded border dark:border-dark-700 bg-light-600 dark:bg-dark-200 p-10">
                <Loading
                  loading={isBusy}
                  className={"absolute rounded w-full h-full"}
                />
                <div className="w-full h-full rounded flex flex-col overflow-clip justify-between gap-3">
                  <PPAImagesPanel
                    title="Uploaded Backgrounds"
                    imageFiles={bgImages}
                    setImageFiles={setBgImages}
                    cols={2}
                  />
                  <div className="w-full flex flex-row-reverse gap-3">
                    <Button
                      onClick={() => {
                        setBgImages(null);
                      }}
                      variant="outline"
                      disabled={isBusy}
                    >
                      Discard
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hidden md:flex bg-light-600 dark:bg-dark-200 min-w-[300px] flex-1 h-full rounded p-10 flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
                <div className="mb-[20px] pl-[20px]">
                  <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
                    Upload Backgrounds
                  </h3>
                  <p className="text-black dark:text-light-200">
                    Upload background images for ad creation
                  </p>
                </div>
                <div className="flex-1 overflow-clip mb-2">
                  <Dropbox
                    name="bg-dropbox"
                    className="w-full h-full"
                    title="Drag and drop images here"
                    icon={<BsImages />}
                    description="Supports: SVG, PNG, JPG or GIF (MAX. 800x400px)"
                    onChange={setBgImages}
                    accept=".png,.jpg,.bmp,.svg,.gif,.jpeg"
                    multiple={true}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="h-[50px] w-full flex justify-between gap-3 bg-light-600 dark:bg-dark-200 border dark:border-dark-700">
            <Button
              className="w-[fit-content] text-light-600"
              onClick={goToStep("images")}
              disabled={isBusy}
            >
              Prev Step
            </Button>
            <Button
              className="w-[fit-content] text-light-600"
              onClick={goToStep("elements")}
              disabled={isBusy}
            >
              Next Step
            </Button>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};

export default PPABgLogo;
