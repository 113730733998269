import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Navbar, Alignment, NavbarDivider } from "@blueprintjs/core";
import styled from "../resource/utils/styled";
import { ExportButton } from "./export-button";
import { usePPAContext } from "../../../hooks/usePPAContext";
import { cleanBase64Strings } from "../sections/cleanbase64";
import useAuth from "../../../hooks/useAuth";
const html_1 = require("../resource/utils/html");
import { EditedImages, publish, toImage } from "../../../actions/pixel_plus_ai";
import { IPContext } from "../../../hooks/IPContext";
import Loading from "../../../components/Loading";
import { FileMenu } from "./file-menu";
import { toast } from "react-toastify";
import { updateSVGInHTML } from "../../../utilities/UpdateSVG";
import { base64ToPolygon } from "../../../utilities/Base64ToPolygon";

const NavbarContainer = styled("div")`
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled("div")`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

export default observer(({ store, isEdit, index }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isNewDesign = pathname === "/new-design";

  const {
    jsonContent,
    setJsonContent,
    setGeneratedHTML,
    setGeneratedJson,
    generatedJson,
    generatedHTML,
    generatedImages,
    generatedURLs,
    setGeneratedImages,
  } = usePPAContext();
  const { isAdmin, isDesigner, isLoading, setLoading } = useAuth();
  const { ip } = useContext(IPContext);

  const handleUse = () => {
    const json = store.toJSON();
    const cts = { image: 0, figure: 0, text: 0};
    const typeName = {image: 'image', figure: 'shape', text: 'text'}

    const newJson = {
      ...json,
      pages: json.pages.map(page => {
        // Separate children by type
        const images = page.children.filter(it => it.type === 'image');
        const texts = page.children.filter(it => it.type === 'text');
        const shapes = page.children.filter(it => it.type === 'shape');
        const others = page.children.filter(it => it.type !== 'image' && it.type !== 'text' && it.type !== 'shape');
    
        // Sort images by width (descending)
        images.sort((a, b) => b.width - a.width);
    
        // Sort texts by length (descending)
        texts.sort((a, b) => b.text.length - a.text.length);
    
        // Assign typeid for images
        const newImages = images.map((it, index) => {
          let typeid;
          if (index === 0) {
            typeid = 'bg_image';
          } else if (index === 1) {
            typeid = 'brand_image';
          } else if (index === 2) {
            typeid = 'logo_image';
          } else {
            typeid = typeName[it.type] + "_section_" + (++cts[it.type]);
          }
          return { ...it, typeid };
        });
    
        // Assign typeid for texts
        const newTexts = texts.map((it, index) => ({
          ...it,
          typeid: typeName[it.type] + "_section_" + (index + 1)
        }));
    
        // Assign typeid for shapes
        const newShapes = shapes.map((it, index) => ({
          ...it,
          typeid: typeName[it.type] + "_section_" + (index + 1)
        }));
    
        // Create a map of original indices
        const originalOrder = page.children.map((child, index) => {
          if (child.type === 'image') {
            return { ...newImages.shift(), originalIndex: index };
          } else if (child.type === 'text') {
            return { ...newTexts.shift(), originalIndex: index };
          } else if (child.type === 'shape') {
            return { ...newShapes.shift(), originalIndex: index };
          } else {
            return { ...child, originalIndex: index };
          }
        });
    
        // Sort by original indices to maintain the original order
        originalOrder.sort((a, b) => a.originalIndex - b.originalIndex);
    
        // Remove the originalIndex property
        const newChildren = originalOrder.map(({ originalIndex, ...rest }) => rest);
    
        return {
          ...page,
          children: newChildren
        };
      })
    };

    const cleanedJsonString = cleanBase64Strings(JSON.stringify(newJson));
    setJsonContent(cleanedJsonString);
    navigate("/pixelplusai/creation?action=customize_generate");
  };

  const handleSave = async () => {
    setLoading(true);
    const json = store.toJSON();

    let htmlData = await html_1.jsonToHTML({
      json: json,
      elementHook: "",
    });
    let newGeneratedJson = [...generatedJson]; // Make a copy of the array
    let newGeneratedHTML = [...generatedHTML];
    const updated_HtmlContent = `<html><head></head><body>${htmlData}</body></html>`;

    // Update the SVG content in the HTML
    const processedHtmlContent = updateSVGInHTML(updated_HtmlContent);

    // Create a DOM parser to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(processedHtmlContent, 'text/html');
    // Find all elements with a clip-path property
    const elements = doc.querySelectorAll('[style*="clip-path"]');
    elements.forEach(element => {
      const style = element.getAttribute('style');
      const clipPathMatch = style.match(/clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/);
      if (clipPathMatch) {
        const base64String = clipPathMatch[1];
        try {
          const polygon = base64ToPolygon(base64String);         
          const newStyle = style.replace(clipPathMatch[0], `clip-path: ${polygon}`);    
          element.setAttribute('style', newStyle);
        } catch (error) {
          console.error('Error converting base64 to polygon:', error);
        }
      }
    });
    // Serialize the updated HTML content
    const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

    newGeneratedJson[Number(index)] = JSON.stringify(json); // Change the element at the index position
    newGeneratedHTML[Number(index)] = updatedHtmlContent;
    const img_url = generatedURLs[Number(index)];
    const res = await EditedImages(updatedHtmlContent, ip, img_url);
    if (res?.data) {
      let newGeneratedImages = [...generatedImages];
      newGeneratedImages[Number(index)] = res.data;
      setGeneratedImages(newGeneratedImages);
    }

    setGeneratedJson(newGeneratedJson);
    setGeneratedHTML(newGeneratedHTML);
    setLoading(false);
    navigate("/pixelplusai/creation");

  };

  const handlePublish = async () => {
    setLoading(true);
    const json = store.toJSON();
    const cts = { image: 0, figure: 0, text: 0};
    const typeName = {image: 'image', figure: 'shape', text: 'text'}

    const newJson = {
      ...json,
      pages: json.pages.map(page => {
        // Separate children by type
        const images = page.children.filter(it => it.type === 'image');
        const texts = page.children.filter(it => it.type === 'text');
        const shapes = page.children.filter(it => it.type === 'figure');
        const others = page.children.filter(it => it.type !== 'image' && it.type !== 'text' && it.type !== 'figure');
    
        // Sort images by width (descending)
        images.sort((a, b) => b.width - a.width);
    
        // Sort texts by length (descending)
        texts.sort((a, b) => b.text.length - a.text.length);
    
        // Assign typeid for images
        const newImages = images.map((it, index) => {
          let typeid;
          if (index === 0) {
            typeid = 'bg_image';
          } else if (index === 1) {
            typeid = 'brand_image';
          } else if (index === 2) {
            typeid = 'logo_image';
          } else {
            typeid = typeName[it.type] + "_section_" + (++cts[it.type]);
          }
          return { ...it, typeid };
        });
    
        // Assign typeid for texts
        const newTexts = texts.map((it, index) => ({
          ...it,
          typeid: typeName[it.type] + "_section_" + (index + 1)
        }));
    
        // Assign typeid for shapes
        const newShapes = shapes.map((it, index) => ({
          ...it,
          typeid: typeName[it.type] + "_section_" + (index + 1)
        }));
    
        // Create a map of original indices
        const originalOrder = page.children.map((child, index) => {
          if (child.type === 'image') {
            return { ...newImages.shift(), originalIndex: index };
          } else if (child.type === 'text') {
            return { ...newTexts.shift(), originalIndex: index };
          } else if (child.type === 'figure') {
            return { ...newShapes.shift(), originalIndex: index };
          } else {
            return { ...child, originalIndex: index };
          }
        });
    
        // Sort by original indices to maintain the original order
        originalOrder.sort((a, b) => a.originalIndex - b.originalIndex);
    
        // Remove the originalIndex property
        const newChildren = originalOrder.map(({ originalIndex, ...rest }) => rest);
    
        return {
          ...page,
          children: newChildren
        };
      })
    };

    let htmlData = await html_1.jsonToHTML({
      json: newJson,
      elementHook: "",
    });
    const updated_HtmlContent = `<html><head></head><body>${htmlData}</body></html>`;

    // Update the SVG content in the HTML
    const processedHtmlContent = updateSVGInHTML(updated_HtmlContent);

    // Create a DOM parser to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(processedHtmlContent, 'text/html');
    // Find all elements with a clip-path property
    const elements = doc.querySelectorAll('[style*="clip-path"]');
    elements.forEach(element => {
      const style = element.getAttribute('style');
      const clipPathMatch = style.match(/clip-path:\s*url\(['"]?data:image\/svg\+xml;base64,([^'")]+)['"]?\)/);
      if (clipPathMatch) {
        const base64String = clipPathMatch[1];
        try {
          const polygon = base64ToPolygon(base64String);         
          const newStyle = style.replace(clipPathMatch[0], `clip-path: ${polygon}`);    
          element.setAttribute('style', newStyle);
        } catch (error) {
          console.error('Error converting base64 to polygon:', error);
        }
      }
    });
    // Serialize the updated HTML content
    const updatedHtmlContent = new XMLSerializer().serializeToString(doc);

    // Convert the HTML string to a DOM node
    try {
      const imageBase64String = await toImage(updatedHtmlContent);
      await publish(JSON.stringify(newJson), imageBase64String.data);
      toast.success("New template has been published successfully!");
    } catch (err) {
      console.error("Failed to convert HTML to image:", err);
      toast.error("Failed to publish new templates.");
    }

    setLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: This will add a semi-transparent dark overlay
          }}
        >
          <Loading loading={isLoading} />
        </div>
      )}
      <NavbarContainer className="bp5-navbar">
        <NavInner>
          {/* <Navbar.Group align={Alignment.LEFT}>
          <FileMenu store={store} project={project} />
        </Navbar.Group> */}

          {isEdit ? (
            <Navbar.Group align={Alignment.RIGHT}>
              <NavbarDivider />
              {!isNewDesign && (
                <>
                  <Button
                    fill
                    intent="primary"
                    className="justify-center items-center px-4 text-[16px]"
                    onClick={() => handleUse()}
                  >
                    Generate
                  </Button>
                  <NavbarDivider />
                </>
              )}
              {isDesigner && isNewDesign && (
                <>
                  <Button
                    fill
                    intent="success"
                    className="justify-center items-center px-4 text-[16px] font-bold"
                    onClick={() => handlePublish()}
                  >
                    Publish
                  </Button>
                  <NavbarDivider />
                </>
              )}
              {!isNewDesign && isAdmin && (
                <>
                  <ExportButton store={store} />
                  <NavbarDivider />
                </>
              )}

              <Button
                fill
                intent="danger"
                icon="undo"
                className="justify-center items-center text-[16px]"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              {/* <NavbarHeading>editor Studio</NavbarHeading> */}
            </Navbar.Group>
          ) : (
            <Navbar.Group align={Alignment.RIGHT}>
              <Button
                fill
                intent="success"
                className="justify-center items-center px-4 text-[16px]"
                onClick={() => handleSave()}
              >
                Save
              </Button>
              <NavbarDivider />
              <Button
                fill
                intent="danger"
                className="justify-center items-center px-4 text-[16px]"
                onClick={() => navigate("/pixelplusai/creation")}
              >
                Cancel
              </Button>
            </Navbar.Group>
          )}
        </NavInner>
      </NavbarContainer>
    </>
  );
});
